import React, { useEffect, useState } from "react";

const NextProject = ({ projectCaseStudy }) => {
  const [caseStudy, setCaseStudy] = useState(false);

  useEffect(() => {
    if (
      projectCaseStudy &&
      (projectCaseStudy.challenge ||
        projectCaseStudy.solution ||
        projectCaseStudy.results)
    ) {
      setCaseStudy(true);
    }
  }, [projectCaseStudy]);

  return (
    <section className="next-prog section-padding">
      {caseStudy && (
        <div className="container px-4 py-5" id="custom-cards">
          <h2 className="pb-2 border-bottom">Case Study</h2>

          <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            <div className="col">
              <div className="card card-cover h-100 overflow-hidden rounded-4 shadow-lg   item-box">
                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                  <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                    Challenge
                  </h3>
                  <p>{projectCaseStudy && projectCaseStudy?.challenge}</p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-cover h-100 overflow-hidden rounded-4 shadow-lg item-box">
                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                  <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                    Solution
                  </h3>
                  <p>{projectCaseStudy && projectCaseStudy?.solution}</p>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-cover h-100 overflow-hidden rounded-4 shadow-lg item-box">
                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                  <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                    Results
                  </h3>
                  <p>{projectCaseStudy && projectCaseStudy?.results}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default NextProject;
