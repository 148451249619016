import React from "react";
import { Link } from "gatsby";

const ProjectDetails2Header = ({ projectHeaderData }) => {
  const getFullDate = (isoDate) => {
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(isoDate);
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear()
    );
  };
  return (
    <section
      className="page-header proj-det bg-img parallaxie valign"
      style={{
        backgroundImage: `url(${projectHeaderData?.featureImage?.guid})`,
      }}
      data-overlay-dark="7"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h6>{projectHeaderData?.projectName}</h6>
              <h2>{projectHeaderData?.projectName}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Client</h6>
              <p>
                <Link to={projectHeaderData?.websiteURL} target="_blank">
                  {projectHeaderData?.projectName}
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Date</h6>
              <p>{getFullDate(projectHeaderData?.date)}</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Tech Stack</h6>
              <p>
                {projectHeaderData?.technologies &&
                  projectHeaderData?.technologies?.map((tech, index) => (
                    // <a href="javascript:void(0);" key={index}>
                    //   {cat}
                    //   {projectHeaderData.technologies.length != index + 1 ? " ," : ""}
                    // </a>
                    <a href="javascript:void(0);" style={{ margin: 0 }}>
                      {(index ? ", " : "") + tech}
                    </a>
                  ))}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            {/* <div className="item mt-30">
              <h6>Tags</h6>
              <p>
                {projectHeaderData.tags.map((tag, index) => (
                  <Link to={tag.link} key={tag.id}>
                    {tag.name}
                    {projectHeaderData.tags.length != index + 1 ? " ," : ""}
                  </Link>
                ))}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails2Header;
