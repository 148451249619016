import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const ProjectGallery = ({ projectGalleryData, props }) => {
  const [images, setImages] = useState(null);

  const _getImages = (data) => {
    let result = [];
    data &&
      data.showcaseImages.forEach((item) => {
        result.push(item.guid);
      });
    return result;
  };

  useEffect(() => {
    const imagesArr = _getImages(projectGalleryData);
    setImages(imagesArr);
  }, [projectGalleryData]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    centerMode: false,
    autoplay: true,
    rows: 1,
    slidesToScroll: 2,
    slidesToShow: 2,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="container">
      <Slider {...settings}>
        {images?.map((img, i) => (
          <div className="img" key={i}>
            <a href="#0" className="col-md-6 popimg py-2">
              <img loading ="lazy"
                className="sliderImg"
                alt=""
                 
                src={img}
                style={{ width: "560px", marginLeft: "-15px" }}
              />
            </a>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ProjectGallery;
